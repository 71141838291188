import { useEffect, useRef, useState } from "react";
import { useUnityContext } from "react-unity-webgl";
import { Box } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import UnityRender from "./render";
import io from "socket.io-client";
import { handleUpdateUserStats } from "../../handlers/user-to-game";
import { Text } from "../../components/text";
import { colors } from "../../styles/styles";

export default function UnityGame({
	game,
	sendMessageToPlayer,
	sendMessageToAllPlayers,
	devicePixelRatio,
	exit,
	socketRef,
	roomID,
	readyToExit,
	afterReadyToExit,
}) {
	const users = useRef([]);
	const [roomCode, setRoomCode] = useState("");
	const [showCode, setShowCode] = useState(false);

	const { unityProvider, sendMessage, isLoaded, addEventListener, removeEventListener, unload } = useUnityContext({
		loaderUrl: game.loaderUrl,
		dataUrl: game.dataUrl,
		frameworkUrl: game.frameworkUrl,
		codeUrl: game.codeUrl,
		streamingAssetsUrl: game.zipUrl,
	});

	const handleSendMessageToPlayer = (name, message) => {
		sendMessageToPlayer(users.current.find((user) => user?.name === name)?.id, message);
	};

	const handleSendMessageToAllPlayers = (message) => {
		sendMessageToAllPlayers(message);
	};

	const handleUpdateStats = (name, stats) => {
		const jsonStats = JSON.parse(stats);

		if (name.length === 13) {
			return;
		}

		handleUpdateUserStats(name, game.id, jsonStats).then((data) => {
			// console.log({data});
		});
	};

	useEffect(() => {
		if (readyToExit) {
			users.current = [];
			if (isLoaded) {
				unload().then(() => {
					afterReadyToExit();
				});
			} else {
				afterReadyToExit();
			}
		}
	}, [readyToExit]);

	const handleExit = () => {
		users.current = [];
		exit();
	};

	useEffect(() => {
		if (!isLoaded) return;
		addEventListener("MessageToPlayer", handleSendMessageToPlayer);
		addEventListener("MessageToAllPlayers", handleSendMessageToAllPlayers);
		addEventListener("Exit", handleExit);
		addEventListener("UpdateStats", handleUpdateStats);
		addEventListener("UpdateMadderPlayerStats", handleUpdateStats);
		addEventListener("ShowCode", () => setShowCode(true));
		addEventListener("HideCode", () => setShowCode(false));

		return () => {
			removeEventListener("MessageToPlayer", handleSendMessageToPlayer);
			removeEventListener("MessageToAllPlayers", handleSendMessageToAllPlayers);
			removeEventListener("Exit", handleExit);
			removeEventListener("UpdateStats", handleUpdateStats);
			removeEventListener("UpdateMadderPlayerStats", handleUpdateStats);
			removeEventListener("ShowCode", () => setShowCode(true));
			removeEventListener("HideCode", () => setShowCode(false));
		};
	}, [isLoaded]);

	useEffect(() => {
		if (!isLoaded) return;
		socketRef.current = io.connect("https://socket.madder.app");
		socketRef.current.emit("create room", game);

		socketRef.current.on("room created", (roomId) => {
			setRoomCode(roomId);
			roomID.current = roomId;
			sendMessage("GameManager", "RoomCode", roomId);
		});

		socketRef.current.on("user joined", (user) => {
			if (!user) return;
			if (users.current.length === 0) {
				const message = { name: "host" };
				socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify(message) });
			} else if (users.current.length > game.minPlayers - 2) {
				const message = { name: "enough players" };
				socketRef.current.emit("message to all players", { roomId: roomID.current, message: JSON.stringify(message) });
			}
			users.current = [...users.current, user];
			sendMessage("GameManager", "PlayerJoined", JSON.stringify({ name: user?.name, stats: user.stats }));
			sendMessage("MadderManager", "RegisterMadderController", JSON.stringify({ name: user?.name, stats: user.stats }));
			socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "name", player: user?.name }) });
		});

		socketRef.current.on("user updated", (user) => {
			if (!user) return;
			users.current.forEach((u, index) => {
				if (!u) return;
				if (u.name === user?.name) {
					users.current[index] = user;
					if (index === 0) {
						socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "host" }) });
					}
					if (users.current.length > game.minPlayers - 2) {
						socketRef.current.emit("message to all players", {
							roomId: roomID.current,
							message: JSON.stringify({ name: "enough players" }),
						});
					}
				}
			});
			socketRef.current.emit("message to player", { id: user.id, message: JSON.stringify({ name: "name", player: user?.name }) });
		});

		socketRef.current.on("individual exit", (id) => {
			const user = users.current.find((user) => user.id === id);
			if (!user) return;
			sendMessage("GameManager", "PlayerLeft", user?.name);
			sendMessage("MadderManager", "UnregisterMadderController", user?.name);
			users.current = users.current.filter((user) => user.id !== id);
		});

		socketRef.current.on("message from player", ({ id, message }) => {
			const user = users.current.find((user) => user.id === id);
			if (!user) return;
			if (message.name === "controller-state") {
				sendMessage("GameManager", "PlayerControllerState", message.state);
				sendMessage("MadderManager", "UpdateMadderControllerState", message.state);
				console.log(message.state);
			} else if (message.name === "move") {
				sendMessage("GameManager", "PlayerControllerState", `${user?.name},${message.move}`);
				sendMessage("MadderManager", "UpdateMadderControllerState", `${user?.name},${message.move}`);
			} else {
				const messageToSend = { player: user?.name, message: message };
				sendMessage("GameManager", "MessageFromPlayer", JSON.stringify(messageToSend));
			}
		});

		const handleBeforeUnload = () => {
			socketRef.current.emit("exit", roomID.current);
		};

		window.addEventListener("beforeunload", handleBeforeUnload);
	}, [isLoaded]);

	return (
		<>
			{!isLoaded ? (
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
						position: "absolute",
						top: 0,
					}}
				>
					{game.loadingFile ? (
						game.loadingFile.endsWith(".json") ? (
							<Player src={game.loadingFile} loop autoplay />
						) : (
							<img src={game.loadingFile} alt="loading" />
						)
					) : null}
				</Box>
			) : null}
			<UnityRender unityProvider={unityProvider} devicePixelRatio={devicePixelRatio} />
			<Box
				style={{
					position: "absolute",
					top: 74,
					right: 0,
					zIndex: 4,
					transform: showCode ? "translateX(0)" : "translateX(calc(100% + 21px))",
					transition: "transform 2s ease",
					width: 168,
					height: 100,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						zIndex: -1,
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="189" height="100" viewBox="0 0 189 100" fill="none">
						<path d="M189 0L21.3499 2.4611e-05L1.85475e-06 50.9825L21.3499 100L189 100V0Z" fill="#BB3D3D" />
					</svg>
				</Box>
				<Text size={18} color={colors.whiteMain} style={{ marginBottom: -10 }}>
					Room code:
				</Text>
				<Text size={54} color={colors.whiteMain} weight={700}>
					{roomCode}
				</Text>
			</Box>
		</>
	);
}
